import React, { useEffect, useState, Component } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import FormValidator from '../FormValidator';
import { withRouter } from '../withRouter';

class ResetPassword extends Component {

  constructor(props) {
  
    super(props);
    this.validator = new FormValidator([
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Password field is required.'
      }, {
        field: 'confirmpassword',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Password confirmation field is required.'
      }, {
        field: 'confirmpassword',
        method: this.passwordMatch, // notice that we are passing a custom function here
        validWhen: true,
        message: 'Password and password confirmation do not match.'
      }
    ]);
    this.state = {
      password: '',
      confirmpassword: '',
      data: [],
      validation: this.validator.valid(),
    }
    this.submitted = false;

  }

  passwordMatch = (confirmation, state) => (state.password === confirmation)
  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleFormSubmit = async event => {
    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({
      validation
    });
    this.submitted = true;
    if (validation.isValid) {
      //reaches here if form validates successfully... 
      let password = this.state.password;
      let confirmpassword = this.state.confirmpassword;
      let email = sessionStorage.getItem('password_reset_email');
      let result = await fetch(window.apiFetchBaseURL+'auth/reset-password', {
        method: 'post',
        body: JSON.stringify({
          password: password,
          confirm_password: confirmpassword,
          email: email,
        }),
        headers: {
          "access-control-allow-origin": "*",
          'Content-Type': 'application/json'
        }
      });

      result = await result.json();
      if (result.status == true) {
        sessionStorage.clear('password_reset_email');         
        sessionStorage.clear('otp_verify');   
        sessionStorage.setItem('resetPassword',true);        
        this.props.navigate("/login");

      } else {
        alert("Email does not exist.");
      }
    }
  }

  render() {

    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
    return (

      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup style={{ width: '60%' }} >
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h1>Reset your password?</h1>
                      <p className="text-medium-emphasis"></p>                    
                      <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="password"
                        name="password"
                        onChange={this.handleChange}
                      />
                    </CInputGroup>
                    {validation.password.message && <p className="ErrorColor">{validation.password.message}</p>}

                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Confirm Password"
                        autoComplete="confirm-password"
                        name="confirmpassword"
                        onChange={this.handleChange}
                      />
                    </CInputGroup>
                    {validation.confirmpassword.message && <p className="ErrorColor">{validation.confirmpassword.message}</p>}

                      <CRow>
                        <CCol xs={6}>
                          <CButton color="primary" className="px-4" onClick={this.handleFormSubmit}>
                            Reset Password
                          </CButton>
                        </CCol>
                        <CCol xs={6} className="text-right">
                          <Link to="/login">
                            <CButton color="link" className="px-0">
                              Login
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    )
  }
}

export default withRouter(ResetPassword)
