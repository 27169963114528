import React from "react";
import {Navigate,Outlet} from 'react-router-dom'

const PublicComponent = ()=>{

    //const auth = sessionStorage.getItem('user');
    const auth = localStorage.getItem('adminUser');
    return auth?<Navigate to="/" />:<Outlet />
}

export default PublicComponent;