import React, { useEffect, useState, Component } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import FormValidator from '../FormValidator';
import { withRouter } from '../withRouter';

class VerifyOTP extends Component {

  constructor(props) {

    super(props);
    this.validator = new FormValidator([
      {
        field: 'otp',
        method: 'isEmpty',
        validWhen: false,
        message: 'The OTP field is required.'
      }
    ]);
    this.state = {
      otp: '',
      data: [],
      validation: this.validator.valid(),
    }

    this.submitted = false;
  }
  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleFormSubmit = async event => {
    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({
      validation
    });
    this.submitted = true;
    if (validation.isValid) {
      //reaches here if form validates successfully... 
      let email = sessionStorage.getItem('password_reset_email');
      let otp = this.state.otp;
      let result = await fetch(window.apiFetchBaseURL+'auth/otp-verify', {
        method: 'post',
        body: JSON.stringify({
          email: email,
          otp: otp,
        }),
        headers: {
          "access-control-allow-origin": "*",
          'Content-Type': 'application/json'
        }
      });

      result = await result.json();
      console.warn(result)
      if (result.status == false) {      
        alert(result.message);
      }else if (result.status == true) {      
        sessionStorage.setItem('otp_verify', 1);            
        this.props.navigate("/reset-password");
      } else {
        alert("Something went wrong");
      }
    }
  }

  render() {

    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
    return (

      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup style={{ width: '60%' }} >
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h1>Verify Your OTP?</h1>
                      <p className="text-medium-emphasis">.</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput placeholder="OTP" type="text" autoComplete="otp" name="otp" onChange={this.handleChange} value={this.state.otp} />
                      </CInputGroup>
                      {validation.otp.message && <p className="ErrorColor">{validation.otp.message}</p>}
                      <CRow>
                        <CCol xs={6}>
                          <CButton color="primary" className="px-4" onClick={this.handleFormSubmit}>
                            Verify OTP
                          </CButton>
                        </CCol>
                        <CCol xs={6} className="text-right">
                          <Link to="/login">
                            <CButton color="link" className="px-0">
                              Login
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    )
  }
}

export default withRouter(VerifyOTP)
