import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import './scss/kalima.scss'
import './scss/datatablecustome.scss';
import './scss/highchart.css';
// import './scss/styles_jubna.scss'
//  import './scss/custom_jubna.scss'
//  import './scss/jubna.scss'
import './assets/brand/bootstarp.js'
import './assets/brand/fontawesome.js'
import PrivateComponent from "./components/PrivateComponent";
import PublicComponent from "./components/PublicComponent";
import ForgotPassword from './views/pages/forgotpassword/ForgotPassword';
import VerifyOTP from './views/pages/VerifyOTP/VerifyOTP';
import ResetPassword from './views/pages/forgotpassword/ResetPassword';
import CheckResetPasswordComponent from "./components/CheckResetPasswordComponent";
import FunctionBasedValidation from "./components/FunctionBasedValidation";
import HttpsRedirect from 'react-https-redirect';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {

  render() {
    return (
      <HttpsRedirect>
        <BrowserRouter >
          <Suspense fallback={loading}>
            <Routes>
              <Route element={<PrivateComponent />}>
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </Route>
              <Route element={<PublicComponent />}>
                <Route exact path="/login" name="Login Page" element={<Login />} />
                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route exact path="/forgot-password" name="Forgot Password Page" element={<ForgotPassword />} />
                <Route exact path="/verify-otp" name="Verify otp Page" element={<VerifyOTP />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                <Route exact path="/function-validation" name="Function Validation" element={<FunctionBasedValidation />} />
              </Route>
              <Route element={<CheckResetPasswordComponent />}>
                <Route exact path="/reset-password" name="Reset Password Page" element={<ResetPassword />} />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </HttpsRedirect>
    )
  }
}
export default App
