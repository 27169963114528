import React, { useEffect, useState, Component } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import FormValidator from '../FormValidator';
import { withRouter } from '../withRouter';

class ForgotPassword extends Component {

  constructor(props) {

    super(props);
    this.validator = new FormValidator([
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Email field is required.'
      }, {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Enter valid email address.'
      }
    ]);
    this.state = {
      email: '',
      data: [],
      validation: this.validator.valid(),
    }

    this.submitted = false;
  }
  handleChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleFormSubmit = async event => {
    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({
      validation
    });
    this.submitted = true;
    if (validation.isValid) {
      //reaches here if form validates successfully... 
      let email = this.state.email;
      let result = await fetch(window.apiFetchBaseURL+'auth/forgot-password', {
        method: 'post',
        body: JSON.stringify({
          email: email,
        }),
        headers: {
          "access-control-allow-origin": "*",
          'Content-Type': 'application/json'
        }
      });

      result = await result.json();
      if (result.status == true) {
        sessionStorage.setItem('password_reset_email', email);         
       this.props.navigate("/verify-otp");

      } else {
        alert("Email does not exist.");
      }
    }
  }

  render() {

    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;
    return (

      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup style={{ width: '60%' }} >
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h1>Forgot your password?</h1>
                      <p className="text-medium-emphasis">Enter your email and click 'Reset Password', and we will send you a opt to reset your password.</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput placeholder="Email" type="email" autoComplete="email" name="email" onChange={this.handleChange} value={this.state.email} />
                      </CInputGroup>
                      {validation.email.message && <p className="ErrorColor">{validation.email.message}</p>}
                      <CRow>
                        <CCol xs={6}>
                          <CButton color="primary" className="px-4" onClick={this.handleFormSubmit}>
                            Reset Password
                          </CButton>
                        </CCol>
                        <CCol xs={6} className="text-right">
                          <Link to="/login">
                            <CButton color="link" className="px-0">
                              Login
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    )
  }
}

export default withRouter(ForgotPassword)
